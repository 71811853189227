<template>
  <div id="wrapper">
    <div class="container-fluid download">
      <div class="row">
        <div class="col">
          <div class="download-wrapper text-center">
            <img class="h3-logo"
                 src="../assets/images/h3_logo.png"
                 alt="logo"
                 @click="goBack" />
            <h2>Download App</h2>
            <p class="small">Enjoy Safe Circle experience plus more when you
              get the Healthy Hip Hop app now available for your
              smartphone or tablet</p>
            <img class="app-store"
                :src='appStoreImg'
                alt="app-store-badge"
                @click="redirect('appStore')" />

            <img class="play-store"
                :src='playStoreImg'
                alt="play-store-badge"
                @click="redirect('playStore')" />
            <br>
            <b-button class="btn btn-h3-small back-btn"
                    @click="goBack"
                    variant="outline-primary"
                    v-if="isLoggedIn && !isMobile">
              Go Back to Site
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <modals-container />
  </div>
</template>

<script>
  const SCGrouping = () => import('@/components/modals/SCGrouping.vue');
  import { isMobile } from 'mobile-device-detect';
  
  export default {
    name : 'Download',
    data() {
      return {
        appStoreImg : 'https://h3-bucket-s3.s3-us-west-2.amazonaws.com/' +
          'appstore_download.svg',
        playStoreImg : 'https://h3-bucket-s3.s3-us-west-2.amazonaws.com/' +
          'playstore_download.svg',
        appStoreLink : 'https://apps.apple.com/us/app/healthy-hip-hop/' +
          'id1456576616',
        playStoreLink : 'https://play.google.com/store/apps/details?' +
          'id=com.healthyhiphop',
        isLoggedIn : this.$store.getters['auth/isLoggedIn'],
        isMobile   : isMobile,
      }
    },
    methods : {
      
      /**
       * Redirect page to download H3 App
       * @param store
       */
      redirect(store) {
        if(store === 'appStore'){
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'mobile-landing-page',
            'platform' : 'website',
            'to'       : 'appstore-ios',
          });
          window.open(this.appStoreLink, '_target');
        } else {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'mobile-landing-page',
            'platform' : 'website',
            'to'       : 'playstore-android',
          });
          window.open(this.playStoreLink, '_target');
        }
        // window.open((store === 'appStore') ? this.appStoreLink :
        //   this.playStoreLink, '_target');
      },

      /**
       * Go Back to Site
       */
      goBack() {
        if (this.isLoggedIn)
          this.$router.push('/dashboard'); //('/browse/main');
        else
          this.$router.push('/');
      },

      /**
       * Show Grouping Modal
       */
      showGroupingModal() {
        this.$modal.show(SCGrouping, {
          friendChatId   : localStorage.getItem('friendChatId'),
          friendUserType : localStorage.getItem('friendUserType'),
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'mobile-landing-page',
        'platform' : 'website',
      });
      if (localStorage.getItem('friendChatId') &&
        localStorage.getItem('friendChatId') !==
        this.$store.getters['user/user'].chat_id)
        this.showGroupingModal();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../assets/scss/components/download";
</style>